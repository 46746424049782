/**
 * Created by lsubramaniam on 11/15/17.
 */
export default class TrackingAssetType {
  crossSell: string
  upSell: string;
  downSell: string;
  mainSell: string;
  constructor() {
    this.crossSell = "false";
    this.upSell = "false";
    this.downSell = "false";
    this.mainSell = "false";
  }
}
