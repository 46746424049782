import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {EventService} from "./app/common/eventlistener/event-service";


if (environment.production) {
  enableProdMode();
}

(window as any).EventService = new EventService();

platformBrowserDynamic().bootstrapModule(AppModule);

