import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {WafCaptchaModalComponent} from "./waf-captcha-modal.component";

@Injectable({
  providedIn: 'root'
})
export class WafCaptchaModalService {

  private modalElementRef: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  open(content: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WafCaptchaModalComponent);
    this.modalElementRef = componentFactory.create(this.injector);

    this.appRef.attachView(this.modalElementRef.hostView);

    const domElem = (this.modalElementRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    if (content) {
      const modalContentElem = domElem.querySelector('.waf-captcha-popup-modal-content');
      modalContentElem?.appendChild(content);
    }
  }

  close() {
    this.appRef.detachView(this.modalElementRef.hostView);
    this.modalElementRef.destroy();
  }
}
