import {SiteDetail} from "./site-detail";
import {CampaignInfo} from "./campaign-info";
import {OfferChainDetail} from "./offer-chain-detail";
import {OptInResponse} from "./optin-response";
/**
 * Created by lsubramaniam on 11/2/17.
 */


export default class OptInResponseModel implements OptInResponse {
  status: string = '';
  message: string = '';

  constructor() {
  }
}
