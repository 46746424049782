export class UserOptinInfo {
  email: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  requiredFields: string[] = [] as string[];
  phoneNumber?: string;
  isNewUser?: boolean;
  demoDateTime?: Date;
}
