import {Injectable} from '@angular/core';
import { GlobalDataService } from '../common/util/globaldata.service';

@Injectable()
export class ExternalJsSupportService {
  constructor(private gd: GlobalDataService) {
  }
  triggerExternalJsFiles() {
    try {
      if (this.gd.site.externalJavaScriptFiles) {
        const head = document.getElementsByTagName('head').item(0);
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.gd.site.externalJavaScriptFiles;
        script.id = 'externalJavaScriptFiles';
        head.appendChild(script);
      }
    } catch (ex) {
      console.log('error sending the data to DMP ' + ex);
    }
  }
  fireReloadAssetData() {
    try {
      setTimeout(() => {
        const reloadAssetDataEvent = new Event('reloadAssetData');
        document.dispatchEvent(reloadAssetDataEvent);
      }, 300);
    } catch (ex) {
      console.log('error sending the data to DMP ' + ex);
    }
  }
}
