export const environment = {
  APP_ENV: 'PROD',
  production: true,
  env: 'prod',
  SITE_DETAILS_API: '/site/api/v1/getSiteDetails',
  ASSET_DETAILS_API : 'https://marketingassets.marketwise.com',
  OFFER_EXPIRY_PAGE: 'https://marketingassets.marketwise.com/test/templates/OfferEndedPage/offer_ended_page.html',
  CREATE_ELETTER_API: '/leads/api/v1/eletter/public',
  CREATE_HOTLIST_API: '/leads/api/v1/hotlist/public',
  CREATE_COI_API: '/leads/api/v1/customer-settings/coi/public',
  DOUBLE_OPTIN_SEND_EMAIL_API: '/leads/api/v1/customer-settings/doi/public',
  EVENTSTREAM_API: '/snowflake/generic',
  EVENTSTREAM_API_KEY: 'g5wZnpMOAV6d3DVWjACKU9Pz86tj10Es39Avoa3n',
  DDG_LOGGER_CLIENT: 'pub2a1eacb4678c8ac666513170783471aa',
  BSS_PIXEL_SRC: '/store/PROD/js/bss-px.min.js',
  SNOW_PLOW_COLLECTOR_URL: 'com-marketwise-prod1.collector.snplow.net',
  AWS_WAF_SCRIPT: 'https://4071fd28d0c2.us-east-2.captcha-sdk.awswaf.com/4071fd28d0c2/jsapi.js'
};
