<div>
  <div class="text-center" *ngIf="isProcessing">
    <img src="assets/images/loading-md.gif">
  </div>
  <asset-manager
    [htmlContent]="htmlContent"
    (onNextPageClickEvent)="onNextPageClickEvent($event)"
    (onSingleOptinClickEvent)="onSingleOptinClickEvent($event)"
    (onDoubleOptinClickEvent)="onDoubleOptinClickEvent($event)"
    (onInvalidUserInfo)="onInvalidUserInfo($event)">
  </asset-manager>
</div>
