<div class="waf-captcha-popup-modal-overlay">
  <div class="waf-captcha-popup-modal-content">
    <button (click)="close()" class="close-waf-captcha-popup">×</button>
    <div>
      <b>Please Complete the CAPTCHA</b>
      <p>To proceed, kindly verify that you are not a robot by completing the CAPTCHA below.</p>
    </div>
    <ng-content></ng-content>
  </div>
</div>
