import {Component, OnInit} from '@angular/core';
import {WafCaptchaModalService} from "./waf-captcha-modal.service";

@Component({
  selector: 'cu-waf-captcha-modal',
  templateUrl: './waf-captcha-modal.component.html',
  styleUrls: ['./waf-captcha-modal.component.css']
})
export class WafCaptchaModalComponent implements OnInit {

  constructor(private modalService: WafCaptchaModalService) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modalService.close();
  }
}

