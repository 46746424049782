/**
 * Created by lsubramaniam on 11/15/17.
 */
export enum ActivityType {
  imp = 'imp',
  click = 'click',
  conv = 'conv',
  orderformvisit = 'orderformvisit',
  landingPage = 'landingpagevisit'
}
