/**
 * Created by lsubramaniam on 11/15/17.
 */
import {GlobalDataService} from '../common/util/globaldata.service';
import {Injectable} from '@angular/core';
import TrackingAssetType from '../models/tracking-asset-type';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IEventStreamData} from '../models/eventstream-data';
import {IUTMData} from '../models/utm-data';
import { AffiliateReferenceData } from '../models/affiliate-reference-data';
import { LoggerService } from './logger.service';

declare var $: any;

@Injectable()
export class TrackerService {
  constructor(private gd: GlobalDataService, private logger: LoggerService, private http: HttpClient) {
  }

  public track(activityType: string) {
    try {
      if (this.gd.asset && this.gd.asset.sourcePubCode) {

        const trackingAssetType = this.getTrackingAssetType();

        const trkData = this.loadTrackerPayLoad(activityType, trackingAssetType);

        if (environment.EVENTSTREAM_API) {
          const evtData = this.getEventStreamData(activityType);
          if (evtData != null) {
            this.postEventStreamData(evtData)
              .subscribe(data => {
                if (data) {
                }
              }, error => {
                this.logger.error(`Error Sending Data to tracker Service for email ${this.gd.email} `, error);
              });
          }
        }
      }
    } catch (e) {
      this.logger.error(`Exception Sending data to tracker service for email ${this.gd.email} `, e);
    }
  }

  private getEventStreamData(activityType: string) {
    let utm: IUTMData;
    utm = {};
    if (this.gd.queryParams.qryUtm_source) {
      utm.utmSource = this.gd.queryParams.qryUtm_source;
    }
    if (this.gd.queryParams.qryUtm_medium) {
      utm.utmMedium = this.gd.queryParams.qryUtm_medium;
    }
    if (this.gd.queryParams.qryUtm_campaign) {
      utm.utmCampaign = this.gd.queryParams.qryUtm_campaign;
    }
    if (this.gd.queryParams.qryUtm_term) {
      utm.utmTerm = this.gd.queryParams.qryUtm_term;
    }
    if (this.gd.queryParams.qryUtm_content) {
      utm.utmContent = this.gd.queryParams.qryUtm_content;
    }
    let brandId = '';
    if (this.gd.site && this.gd.site.brandId) {
      brandId = this.gd.site.brandId;
    }
    let email = '';
    $('.sna-email').each(function(i, obj) {
      if (obj.value) {
        email = obj.value;
      }
    });
    let snaid = '';
    if (this.gd.queryParams.snaid) {
      snaid = this.gd.queryParams.snaid;
    }
    let encryptedSnaid = '';
    if (this.gd.queryParams.encryptedSnaid) {
      encryptedSnaid = decodeURIComponent(this.gd.queryParams.encryptedSnaid);
    }
    const eventStreamData = {} as IEventStreamData;
    eventStreamData.cid = this.gd.queryParams.campaignId;
    eventStreamData.at = activityType;
    eventStreamData.eid = this.gd.queryParams.effortId;
    eventStreamData.pubcode = this.gd.asset.sourcePubCode;
    eventStreamData.oc = this.gd.site.offerChainId;
    eventStreamData.oid = this.gd.asset.sourceOfferId;
    eventStreamData.aid = this.gd.asset.sourceParentAssetId;
    eventStreamData.asid = this.gd.asset.sourceAssetCode;
    eventStreamData.atype = this.gd.asset.sourceAssetType;
    eventStreamData.offeraid = this.gd.asset.sourceAssetCode;
    eventStreamData.source = this.getSourceForTracker();
    eventStreamData.email = email;
    eventStreamData.plcid = this.gd.queryParams.placementId;
    eventStreamData.promotionLink = encodeURI(window.location.href);
    eventStreamData.brandId = brandId;
    eventStreamData.eventCategory = 'clickstream';
    eventStreamData.eventStatus = 'success';
    eventStreamData.eventType = activityType;
    eventStreamData.utm = utm;
    eventStreamData.sessionId = this.gd.sessionId;
    eventStreamData.snaid = snaid;
    eventStreamData.encryptedSnaid = encryptedSnaid;
    if (this.gd.queryParams.clickId) {
      eventStreamData.affiliateRefData = this.getLinkTrustData();
    }
    return eventStreamData;
  }

  private getTrackerUrl(url: string) {
    if (url.startsWith('http://')) {
      url.replace('http://', 'https://');
    } else if (url.indexOf('//') > -1) {
      url = 'https://' + url.split('//')[1];
    } else if (url.indexOf('//') === -1) {
      url = 'https://' + url;
    }
    return url;
  }

  private postEventStreamData(eventStreamData: IEventStreamData) {
    return new Observable<string>(subscriber => {
      const url = this.getTrackerUrl(`${window.location.host}${environment.EVENTSTREAM_API}`);
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key': environment.EVENTSTREAM_API_KEY
        })
      };
      this.http.post(url, eventStreamData, httpOptions).subscribe((response) => {
        try {
          subscriber.next();
          subscriber.complete();
        } catch (error) {
          subscriber.next();
          subscriber.complete();
        }
      }, error => {
        this.logger.error(`Error sending stream event data for email ${this.gd.email}`, error);
      });
    });
  }

  private getSourceForTracker() {
    let source = '';

    if (this.gd.queryParams.test
      && this.gd.queryParams.test.toUpperCase() === 'TRUE') {
      source = 'test';
    }

    return source;
  }

  private loadTrackerPayLoad(activityType: string, trackingAssetType: TrackingAssetType) {

    let utmVariables = '';
    if (this.gd.queryParams.qryUtm_source) {
      utmVariables += 'utm_source=' + this.gd.queryParams.qryUtm_source + ';';
    }
    if (this.gd.queryParams.qryUtm_medium) {
      utmVariables += 'utm_medium=' + this.gd.queryParams.qryUtm_medium + ';';
    }
    if (this.gd.queryParams.qryUtm_campaign) {
      utmVariables += 'utm_campaign=' + this.gd.queryParams.qryUtm_campaign + ';';
    }
    if (this.gd.queryParams.qryUtm_term) {
      utmVariables += 'utm_term=' + this.gd.queryParams.qryUtm_term + ';';
    }
    if (this.gd.queryParams.qryUtm_content) {
      utmVariables += 'utm_content=' + this.gd.queryParams.qryUtm_content;
    }
    let email = '';
    $('.sna-email').each(function(i, obj) {
      if (obj.value) {
        email = obj.value;
      }
    });
    let snaid = '';
    if (this.gd.queryParams.snaid) {
      snaid = this.gd.queryParams.snaid;
    }
    let encryptedSnaid = '';
    if (this.gd.queryParams.encryptedSnaid) {
      encryptedSnaid = decodeURIComponent(this.gd.queryParams.encryptedSnaid);
    }
    const trkData = {
      cid: this.gd.queryParams.campaignId,
      at: activityType,
      eid: this.gd.queryParams.effortId,
      pubcode: this.gd.asset.sourcePubCode,
      oc: this.gd.site.offerChainId,
      oid: this.gd.asset.sourceOfferId,
      aid: this.gd.asset.sourceParentAssetId,
      agrp: '',
      asid: this.gd.asset.sourceAssetCode,
      atype: this.gd.asset.sourceAssetType,
      offeraid: this.gd.asset.sourceAssetCode,
      snaid: snaid,
      encryptedSnaid: encryptedSnaid,
      transId: '',
      source: this.getSourceForTracker(),
      email: $('.sna-email').val(),
      xsell: trackingAssetType.crossSell,
      upsell: trackingAssetType.upSell,
      downsell: trackingAssetType.downSell,
      // Placement
      plcid: this.gd.queryParams.placementId,
      plcustom: '',
      pldest: '',
      pldesttype: '',
      plho: '',
      pltype: '',
      plschd: '',
      crossDomain: true,
      oneTimePrice: '',
      productName: '',
      promotionLink: encodeURI(window.location.href),
      utmVariableStr: utmVariables
    };
    return trkData;
  }

  private getTrackingAssetType() {
    const trackingAssetType = new TrackingAssetType();
    trackingAssetType.crossSell = 'false';
    trackingAssetType.upSell = 'false';
    trackingAssetType.downSell = 'false';
    trackingAssetType.mainSell = 'true';
    return trackingAssetType;
  }

  private nullCheck(value) {
    if (value) {
      return value;
    }
    return '';
  }

  private getLinkTrustData(): AffiliateReferenceData {
    const linkTrustData = {} as AffiliateReferenceData;
    linkTrustData.clickId = this.gd.queryParams.clickId;
    return linkTrustData;
  }
}
