export const formOptions = {
        fields: {
                transform: function (value, elt) {
                        const storeClassesToSuppress = [
                                'sna-first-name',
                                'sna-last-name',
                                'sna-email',
                                'sna-address-street',
                                'sna-address-city',
                                'sna-address-state',
                                'sna-address-zipcode',
                                'sna-address-country',
                                'sna-phone-number'
                        ];
                        if (getInsersectionSize(Array.from(elt.classList), storeClassesToSuppress) >= 1) {
                                return 'REDACTED';
                        };
                        return value;
                }
        }
}

function getInsersectionSize(listA: any, listB: any) {
        const allElements = [...listA, ...listB];
        const intersection = allElements.filter(x => listA.includes(x) && listB.includes(x));
        return new Set(intersection).size
}
