import {SiteDetail} from "./site-detail";
import {CampaignInfo} from "./campaign-info";
import {OfferChainDetail} from "./offer-chain-detail";
import {SiteDetailRequest} from "./site-detail-request";
/**
 * Created by lsubramaniam on 11/2/17.
 */


export default class SiteDetailRequestModel implements SiteDetailRequest {
  cid: string;
  eid: string;
  chainId: string;
  plcid: string;
  preview: boolean;
  baseUrl: string;
  constructor() {
  }
}
