import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, delay, map, switchMap} from 'rxjs/operators';
import {WafCaptchaModalService} from '../../waf-captcha-modal/waf-captcha-modal.service';
import {ScriptLoaderService} from './script-loader.service';
import {GlobalDataService} from '../../common/util/globaldata.service';
import {UtilityService} from '../../common/util/utils.service';
import {LoggerService} from '../logger.service';

declare const AwsWafIntegration: any;
declare const AwsWafCaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class AwsWafWrapperService {

  private static readonly retryInterval = 2000;
  private static readonly maxRetries = 10;

  constructor(private wafCaptchaModalService: WafCaptchaModalService,
              private scriptLoaderService: ScriptLoaderService,
              private loggerService: LoggerService,
              private gd: GlobalDataService,
              private us: UtilityService) {
  }

  public post(url: string, body: any, headers: any): Observable<any> {
    return this.fetchWithRetry(url, {method: 'POST', headers: headers, body: body});
  }

  private fetchWithRetry(
    url: string,
    options: any,
    forceRefreshToken: boolean = false,
    challengeRetriesLeft: number = AwsWafWrapperService.maxRetries
  ): Observable<any> {
    return this.getAwsWafTokenSafely(forceRefreshToken).pipe(
      switchMap((token: string) => {
        options.headers["X-Aws-Waf-Token"] = token;
        return from(AwsWafIntegration.fetch(url, options));
      }),
      switchMap((response: Response) => this.handleFetchResponse(response, url, options, challengeRetriesLeft)),
      catchError((error: any) => this.handleFetchError(error, url))
    );
  }

  private handleFetchResponse(
    response: Response,
    url: string,
    options: any,
    challengeRetriesLeft: number
  ): Observable<any> {
    switch (response.status) {
      case 200:
        this.loggerService.info(`${url} API call completed successfully`);
        return from(response.json());
      case 202:
        return this.handleChallengeResponse(url, options, challengeRetriesLeft);
      case 405:
        return this.handleCaptchaResponse(url, options);
      default:
        this.loggerService.ddError(`${url} API call failed with status code ${response.status}`);
        return throwError(() => new Error(`Http response is unsuccessful: ${response.status}`));
    }
  }

  private handleChallengeResponse(
    url: string,
    options: any,
    challengeRetriesLeft: number
  ): Observable<any> {
    if (challengeRetriesLeft > 0) {
      this.loggerService.ddInfo(`${url} API call failed with Challenge, retrying... Retries left: ${challengeRetriesLeft - 1}`);
      return this.fetchWithRetry(url, options, true, challengeRetriesLeft - 1);
    }
    return throwError(() => new Error('Max retries reached without resolving the challenge.'));
  }

  private handleCaptchaResponse(
    url: string,
    options: any
  ): Observable<any> {
    this.loggerService.ddInfo(`${url} API call failed with Captcha`);
    return from(this.captchaRequest(this.gd.site.awsWafCaptchaKey)).pipe(
      switchMap(() => this.fetchWithRetry(url, options))
    );
  }

  private handleFetchError(error: any, url: string): Observable<never> {
    this.loggerService.ddError(`Error during ${url} API call: ${error}`, error);
    return throwError(() => new Error(`API call error: ${error.message || error}`));
  }

  private getAwsWafTokenSafely(forceRefreshToken: boolean): Observable<any> {
    return from(this.scriptLoaderService.loadAwsWafScript()).pipe(
      switchMap(() => this.retryWithDelay(() => this.getAwsWafToken(forceRefreshToken), AwsWafWrapperService.maxRetries)),
      catchError(error => throwError(() => new Error('Error loading AWS WAF script or getting token: ' + error)))
    );
  }

  private getAwsWafToken(forceRefreshToken = false): Observable<any> {
    let operation = () => {
      if (forceRefreshToken) {
        return AwsWafIntegration.forceRefreshToken();
      }
      return AwsWafIntegration.getToken();
    };
    if (typeof AwsWafIntegration !== 'undefined') {
      return from(operation()).pipe(
        map(token => {
          if (token) {
            return token;
          } else {
            throw new Error('Empty token');
          }
        }),
        catchError(error => {
          console.error('Error getting token:', error);
          throw error;
        })
      );
    } else {
      return throwError(() => new Error('AwsWafIntegration is not defined'));
    }
  }

  private retryWithDelay<T>(operation: () => Observable<T>, retriesLeft: number): Observable<T> {
    return operation().pipe(
      catchError(error => {
        if (retriesLeft > 0) {
          console.log(`Operation failed, retrying... Retries left: ${retriesLeft - 1}`);
          return of(null).pipe(
            delay(AwsWafWrapperService.retryInterval),
            switchMap(() => this.retryWithDelay(operation, retriesLeft - 1))
          );
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private captchaRequest(awsWafCaptchaKey): Promise<void> {
    return new Promise((resolve, reject) => {
      this.addCaptchaContainer();
      AwsWafCaptcha.renderCaptcha(document.querySelector("#captcha-container"), {
        apiKey: awsWafCaptchaKey,
        onSuccess: () => {
          this.hideCaptchaContainer();
          resolve();
        },
        onError: (error: any) => reject(error),
        skipTitle: true
      });
    });
  }

  private addCaptchaContainer() {
    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', 'captcha-container');
    this.wafCaptchaModalService.open(newDiv);
  }

  private hideCaptchaContainer() {
    this.wafCaptchaModalService.close();
    const captchaContainer = document.getElementById('captcha-container');
    if (captchaContainer) {
      captchaContainer.style.display = 'none';
    }
  }
}
