
/**
 * Created by lsubramaniam on 4/16/18.
 */
import {EventList} from "./event-list";
import {EventListeners} from "./event-listeners";

export class EventService {

  private _eventListenersList: EventListeners[] = [];

  public dispatchEvent(event: EventList, error: any, data: any) {
    const functionList = this.getEventListeners(event);
    for (const func of functionList) {
      func(error, data);
    }
  }

  public addEventListener(event: EventList, callback: Function) {
    this.pushEventListener(event, callback);
  }

  get Events() {
    return EventList;
  }

  private getEventListeners(event: EventList): Function[] {
    for (const eventListener of this._eventListenersList) {
      if (eventListener.event === event) {
        return eventListener.listeners;
      }
    }
    return [];
  }

  private pushEventListener(event: EventList, callback: Function) {
    for (const eventListener of this._eventListenersList) {
      if (eventListener.event === event) {
        eventListener.listeners.push(callback);
        break;
      }
    }
    const newEventListener = new EventListeners();
    newEventListener.event = event;
    newEventListener.listeners.push(callback);
    this._eventListenersList.push(newEventListener);
  }

}
