import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {datadogLogs} from '@datadog/browser-logs';

@Injectable()
export class LoggerService {
  LEADGEN_UI = `leadgen-ui-${environment.env}`;

  constructor() {
    this.initializeDatadogLog();
  }

  /**
   * log method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  log(message: string, messageAttributes?: {}) {
    console.log(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  /**
   * Debug method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  debug(message: string, messageAttributes?: {}) {
    console.debug(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  /**
   * Info method to log the custom events to console.
   * @param message The message of your log.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  info(message: string, messageAttributes?: {}) {
    console.info(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  /**
   * Info method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  ddInfo(message: string, messageAttributes?: {}) {
    console.info(`${this.LEADGEN_UI} ${message}`, messageAttributes);
    datadogLogs.logger.info(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  /**
   * warn method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  warn(message: string, messageAttributes?: {}) {
    console.warn(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  /**
   * error method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  error(message: string, messageAttributes?: {}) {
    console.error(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  ddError(message: string, messageAttributes?: {}) {
    console.error(`${this.LEADGEN_UI} ${message}`, messageAttributes);
    datadogLogs.logger.error(`${this.LEADGEN_UI} ${message}`, messageAttributes);
  }

  private initializeDatadogLog() {
    datadogLogs.init({
      clientToken: environment.DDG_LOGGER_CLIENT,
      service: this.LEADGEN_UI,
      site: 'datadoghq.com',
      env: environment.APP_ENV,
      version: '1.0.0',
    });
  }
}
