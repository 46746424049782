import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LoggerService} from '../logger.service';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor(private loggerService: LoggerService) {
  }

  loadAwsWafScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${environment.AWS_WAF_SCRIPT}"]`)) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = environment.AWS_WAF_SCRIPT;
      script.defer = true;
      script.onload = () => {
        this.loggerService.info('AWS WAF script loaded successfully');
        resolve();
      };
      script.onerror = (error) => {
        this.loggerService.ddError('Error loading AWS WAF script:', error);
        reject(error);
      };
      document.body.appendChild(script);
    });
  }
}
