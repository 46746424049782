import { BrowserModule, Title } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import { GlobalDataService } from './common/util/globaldata.service';
import { RouterModule, Routes } from '@angular/router';
import { AssetManagerComponent } from './components/asset-manager/asset-manager.component';
import { UtilityService } from './common/util/utils.service';
import { SiteManagerComponent } from './containers/site-manager/site-manager.component';
import { TrackerService } from './services/tracker-service';
import { LoggerService } from './services/logger.service';
import { HttpClientModule } from '@angular/common/http';
import { ExternalJsSupportService } from './services/externalJsSupport.service';
import {ScriptLoaderService} from './services/util/script-loader.service';
import { WafCaptchaModalComponent } from './waf-captcha-modal/waf-captcha-modal.component';

const routes: Routes = [];

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    AppComponent,
    AssetManagerComponent,
    SiteManagerComponent,
    WafCaptchaModalComponent
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    GlobalDataService,
    UtilityService,
    TrackerService,
    Title,
    LoggerService,
    ExternalJsSupportService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ScriptLoaderService],
      multi: true
    }
  ]
})

export class AppModule {
}

export function initializeApp(scriptLoaderService: ScriptLoaderService): () => Promise<void> {
  return () => scriptLoaderService.loadAwsWafScript();
}
