import { Component, OnInit } from '@angular/core';
import {GlobalDataService} from "./common/util/globaldata.service";
import { LoggerService } from './services/logger.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []
})
export class AppComponent implements OnInit {

  constructor(private gd: GlobalDataService, private log: LoggerService) {
    (window as any).globalStoreData = gd;
  }

  ngOnInit() {
  }
}
