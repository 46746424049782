/**
 * Created by lsubramaniam on 10/30/17.
 */
import {Injectable} from '@angular/core';
import {AssetDetail} from '../../models/asset-detail';
import SiteDetailModel from '../../models/site-detail-model';
import {UserOptinInfo} from '../../models/user-optin-info';

export interface QueryParams {
  campaignId: string;
  effortId: string;
  offerChainId: string;
  placementId: string;
  sellerPlacementId: string;
  preview: boolean;
  assetId: string;
  encryptedSnaid: string;
  doubleOptInParams: string;
  encid: string;
  eneid: string;
  test: string;
  coi: boolean;
  coiParams: string;
  src: string;
  qryUtm_source: string;
  qryUtm_medium: string;
  qryUtm_campaign: string;
  qryUtm_term: string;
  qryUtm_content: string;
  channel: string;
  oneClick: string;
  qryEmailJobId: string;
  snaid: string;
  clickId?: string;
  autotest?: string | '';
}

export interface SiteParams {
  assetDetails: AssetDetail[];
  selOfferChainId: string;
}

@Injectable()
export class GlobalDataService {
  queryParams: QueryParams;
  siteParams: SiteParams;
  user: UserOptinInfo;
  vars: {};
  assetDetails: Array<AssetDetail>;
  asset: AssetDetail = {} as AssetDetail;
  site: SiteDetailModel = {} as SiteDetailModel;
  email: string;
  sessionId: string;
  brandId: string;
  isCoreg: boolean;
  encryptedSnaid: string;
  fieldSelectors = {};
  snowplowSessionId: string;
  activeSubscriber: boolean;
  constructor() {
    this.queryParams = {} as QueryParams;
    this.queryParams.coi = false;
    this.queryParams.oneClick = 'false';
    this.siteParams = {} as SiteParams;
    this.vars = {};
    this.assetDetails = [];
    this.asset = {} as AssetDetail;
    this.site = {} as SiteDetailModel;
    this.email = '';
    this.user = new UserOptinInfo();
    this.fieldSelectors['email'] = new FieldSelector('email', '.sna-email', '.sna-email-invalid');
    this.fieldSelectors['firstName'] = new FieldSelector('firstName', '.sna-first-name', '.sna-first-name-invalid');
    this.fieldSelectors['lastName'] = new FieldSelector('lastName', '.sna-last-name', '.sna-last-name-invalid');
    this.fieldSelectors['street'] = new FieldSelector('street', '.sna-address-street', '.sna-address-street-invalid');
    this.fieldSelectors['city'] = new FieldSelector('city', '.sna-address-city', '.sna-address-city-invalid');
    this.fieldSelectors['state'] = new FieldSelector('state', '.sna-address-state', '.sna-address-state-invalid');
    this.fieldSelectors['zipcode'] = new FieldSelector('zipcode', '.sna-address-zipcode', '.sna-address-zipcode-invalid');
    this.fieldSelectors['country'] = new FieldSelector('country', '.sna-address-country', '.sna-address-country-invalid');
    this.fieldSelectors['phoneNumber'] = new FieldSelector('phoneNumber', '.sna-phone-number', '.sna-phone-number-invalid');
    this.fieldSelectors['demoDateTime'] = new FieldSelector('demoDateTime', '.sna-demo-datetime', '.sna-demo-datetime-invalid');
    this.sessionId = '';
    this.brandId = '';
    this.isCoreg = false;
    this.encryptedSnaid = '';
    this.snowplowSessionId = '';
    this.activeSubscriber = false;
  }
}

export class FieldSelector {
  field: string;
  fieldClass: string;
  errorClass: string;
  constructor(field: string, fieldClass: string, errorClass: string) {
    this.field = field;
    this.fieldClass = fieldClass;
    this.errorClass = errorClass;
  }
}
